import phantom from "../../assets/recentprojects/phantom.png";
import hodler from "../../assets/recentprojects/hodler.png";
import cuantv from "../../assets/recentprojects/cuantv.png";
import leslar from "../../assets/recentprojects/leslar.png";
import prestige from "../../assets/recentprojects/prestige.png";
import rooma from "../../assets/recentprojects/rooma.png";

export const imageProjects = {
  phantom,
  hodler,
  cuantv,
  leslar,
  prestige,
  rooma,
};
